// import lumina_logo from '../images/lumina_spa_images/lumina_logo_for_card.png'
import lumina_logo from '../images/lumina_spa_images/lumina_logo_for_card_SVG.svg'

import New_Collection_Cake_01 from '../images/cakeImages/00_New_Collection/j_cake_01.png'
import New_Collection_Cake_02 from '../images/cakeImages/00_New_Collection/j_cake_02.png'
import New_Collection_Cake_03 from '../images/cakeImages/00_New_Collection/j_cake_03.png'
import New_Collection_Cake_04 from '../images/cakeImages/00_New_Collection/j_cake_04.png'
import New_Collection_Cake_05 from '../images/cakeImages/00_New_Collection/j_cake_05.png'
import New_Collection_Cake_06 from '../images/cakeImages/00_New_Collection/j_cake_06.png'
import New_Collection_Cake_07 from '../images/cakeImages/00_New_Collection/j_cake_07.png'
import New_Collection_Cake_08 from '../images/cakeImages/00_New_Collection/j_cake_08.png'
import New_Collection_Cake_09 from '../images/cakeImages/00_New_Collection/j_cake_09.png'
import New_Collection_Cake_10 from '../images/cakeImages/00_New_Collection/j_cake_10.png'
import New_Collection_Cake_11 from '../images/cakeImages/00_New_Collection/j_cake_11.png'
import New_Collection_Cake_12 from '../images/cakeImages/00_New_Collection/j_cake_12.png'
import New_Collection_Cake_13 from '../images/cakeImages/00_New_Collection/j_cake_13.png'
import New_Collection_Cake_14 from '../images/cakeImages/00_New_Collection/j_cake_14.png'
import New_Collection_Cake_15 from '../images/cakeImages/00_New_Collection/j_cake_15.png'
import New_Collection_Cake_16 from '../images/cakeImages/00_New_Collection/j_cake_16.png'
import New_Collection_Cake_17 from '../images/cakeImages/00_New_Collection/j_cake_17.png'
import New_Collection_Cake_18 from '../images/cakeImages/00_New_Collection/j_cake_18.png'
import New_Collection_Cake_19 from '../images/cakeImages/00_New_Collection/j_cake_19.png'
import New_Collection_Cake_20 from '../images/cakeImages/00_New_Collection/j_cake_20.png'
import New_Collection_Cake_21 from '../images/cakeImages/00_New_Collection/j_cake_21.png'
import New_Collection_Cake_22 from '../images/cakeImages/00_New_Collection/j_cake_22.png'
import New_Collection_Cake_23 from '../images/cakeImages/00_New_Collection/j_cake_23.png'
import New_Collection_Cake_24 from '../images/cakeImages/00_New_Collection/j_cake_24.png'
import New_Collection_Cake_25 from '../images/cakeImages/00_New_Collection/j_cake_25.png'

import Birthday_Cakes_01 from '../images/cakeImages/01_Birthday_Cakes/Birthday_Cakes_01.png'
import Birthday_Cakes_02 from '../images/cakeImages/01_Birthday_Cakes/Birthday_Cakes_02.png'
import Birthday_Cakes_03 from '../images/cakeImages/01_Birthday_Cakes/Birthday_Cakes_03.png'
import Birthday_Cakes_04 from '../images/cakeImages/01_Birthday_Cakes/Birthday_Cakes_04.png'
import Birthday_Cakes_05 from '../images/cakeImages/01_Birthday_Cakes/Birthday_Cakes_05.png'
import Birthday_Cakes_06 from '../images/cakeImages/01_Birthday_Cakes/Birthday_Cakes_06.png'
import Birthday_Cakes_07 from '../images/cakeImages/01_Birthday_Cakes/Birthday_Cakes_07.png'
import Birthday_Cakes_08 from '../images/cakeImages/01_Birthday_Cakes/Birthday_Cakes_08.png'
import Birthday_Cakes_09 from '../images/cakeImages/01_Birthday_Cakes/Birthday_Cakes_09.png'

import Wedding_Anniversary_Cakes_01 from '../images/cakeImages/02_A_Wedding_Anniversary_Cakes/Wedding_Anniversary_Cakes_01.png'
import Wedding_Anniversary_Cakes_02 from '../images/cakeImages/02_A_Wedding_Anniversary_Cakes/Wedding_Anniversary_Cakes_02.png'
import Wedding_Anniversary_Cakes_03 from '../images/cakeImages/02_A_Wedding_Anniversary_Cakes/Wedding_Anniversary_Cakes_03.png'
import Wedding_Anniversary_Cakes_04 from '../images/cakeImages/02_A_Wedding_Anniversary_Cakes/Wedding_Anniversary_Cakes_04.png'
import Wedding_Anniversary_Cakes_05 from '../images/cakeImages/02_A_Wedding_Anniversary_Cakes/Wedding_Anniversary_Cakes_05.png'
import Wedding_Anniversary_Cakes_06 from '../images/cakeImages/02_A_Wedding_Anniversary_Cakes/Wedding_Anniversary_Cakes_06.png'

import Engagement_Cakes_01 from '../images/cakeImages/02_B_Engagement_Cakes/Engagement_Cakes_01.png'
import Engagement_Cakes_02 from '../images/cakeImages/02_B_Engagement_Cakes/Engagement_Cakes_02.png'
import Engagement_Cakes_03 from '../images/cakeImages/02_B_Engagement_Cakes/Engagement_Cakes_03.png'
import Engagement_Cakes_04 from '../images/cakeImages/02_B_Engagement_Cakes/Engagement_Cakes_04.png'
import Engagement_Cakes_05 from '../images/cakeImages/02_B_Engagement_Cakes/Engagement_Cakes_05.png'

import Queen_Cakes_01 from '../images/cakeImages/02_C_Queen_Cakes/Queen_Cakes_01.png'
import Queen_Cakes_02 from '../images/cakeImages/02_C_Queen_Cakes/Queen_Cakes_02.png'
import Queen_Cakes_03 from '../images/cakeImages/02_C_Queen_Cakes/Queen_Cakes_03.png'
import Queen_Cakes_04 from '../images/cakeImages/02_C_Queen_Cakes/Queen_Cakes_04.png'
import Queen_Cakes_05 from '../images/cakeImages/02_C_Queen_Cakes/Queen_Cakes_05.png'
import Queen_Cakes_06 from '../images/cakeImages/02_C_Queen_Cakes/Queen_Cakes_06.png'

import Bachelorette_Party_Cakes_01 from '../images/cakeImages/02_D_Bachelorette_Party_Cakes/Bachelorette_Party_Cakes_01.png'
import Bachelorette_Party_Cakes_02 from '../images/cakeImages/02_D_Bachelorette_Party_Cakes/Bachelorette_Party_Cakes_02.png'
import Bachelorette_Party_Cakes_03 from '../images/cakeImages/02_D_Bachelorette_Party_Cakes/Bachelorette_Party_Cakes_03.png'
import Bachelorette_Party_Cakes_04 from '../images/cakeImages/02_D_Bachelorette_Party_Cakes/Bachelorette_Party_Cakes_04.png'
import Bachelorette_Party_Cakes_05 from '../images/cakeImages/02_D_Bachelorette_Party_Cakes/Bachelorette_Party_Cakes_05.png'

import Baby_Shower_Cakes_01 from '../images/cakeImages/03_A_Baby_Shower_Cakes/Baby_Shower_Cakes_01.png'
import Baby_Shower_Cakes_02 from '../images/cakeImages/03_A_Baby_Shower_Cakes/Baby_Shower_Cakes_02.png'
import Baby_Shower_Cakes_03 from '../images/cakeImages/03_A_Baby_Shower_Cakes/Baby_Shower_Cakes_03.png'
import Baby_Shower_Cakes_04 from '../images/cakeImages/03_A_Baby_Shower_Cakes/Baby_Shower_Cakes_04.png'
import Baby_Shower_Cakes_05 from '../images/cakeImages/03_A_Baby_Shower_Cakes/Baby_Shower_Cakes_05.png'

import Gender_Determination_Cakes_01 from '../images/cakeImages/03_B_Gender_Determination_Cakes/Gender_Determination_Cakes_01.png'
import Gender_Determination_Cakes_02 from '../images/cakeImages/03_B_Gender_Determination_Cakes/Gender_Determination_Cakes_02.png'
import Gender_Determination_Cakes_03 from '../images/cakeImages/03_B_Gender_Determination_Cakes/Gender_Determination_Cakes_03.png'
import Gender_Determination_Cakes_04 from '../images/cakeImages/03_B_Gender_Determination_Cakes/Gender_Determination_Cakes_04.png'

import Forty_Days_Party_Cakes_01 from '../images/cakeImages/03_C_Forty_Day_Party_Cakes/Forty_Days_Party_Cakes_01.png'
import Forty_Days_Party_Cakes_02 from '../images/cakeImages/03_C_Forty_Day_Party_Cakes/Forty_Days_Party_Cakes_02.png'
import Forty_Days_Party_Cakes_03 from '../images/cakeImages/03_C_Forty_Day_Party_Cakes/Forty_Days_Party_Cakes_03.png'
import Forty_Days_Party_Cakes_04 from '../images/cakeImages/03_C_Forty_Day_Party_Cakes/Forty_Days_Party_Cakes_04.png'

import Surgery_Success_Party_Cakes_01 from '../images/cakeImages/04_Surgery_Success_Party_Cakes/Surgery_Success_Party_Cakes_01.png'
import Surgery_Success_Party_Cakes_02 from '../images/cakeImages/04_Surgery_Success_Party_Cakes/Surgery_Success_Party_Cakes_02.png'
import Surgery_Success_Party_Cakes_03 from '../images/cakeImages/04_Surgery_Success_Party_Cakes/Surgery_Success_Party_Cakes_03.png'
import Surgery_Success_Party_Cakes_04 from '../images/cakeImages/04_Surgery_Success_Party_Cakes/Surgery_Success_Party_Cakes_04.png'
import Surgery_Success_Party_Cakes_05 from '../images/cakeImages/04_Surgery_Success_Party_Cakes/Surgery_Success_Party_Cakes_05.png'

import Achieving_Goals_Cakes_01 from '../images/cakeImages/05_Achieving_Goals_Cakes/Achieving_Goals_Cakes_01.png'
import Achieving_Goals_Cakes_02 from '../images/cakeImages/05_Achieving_Goals_Cakes/Achieving_Goals_Cakes_02.png'
import Achieving_Goals_Cakes_03 from '../images/cakeImages/05_Achieving_Goals_Cakes/Achieving_Goals_Cakes_03.png'
import Achieving_Goals_Cakes_04 from '../images/cakeImages/05_Achieving_Goals_Cakes/Achieving_Goals_Cakes_04.png'
import Achieving_Goals_Cakes_05 from '../images/cakeImages/05_Achieving_Goals_Cakes/Achieving_Goals_Cakes_05.png'

import Farewell_Traveler_Cakes_01 from '../images/cakeImages/06_Farewell_And_Reception_Traveler_Cakes/Farewell_Traveler_Cakes_01.png'
import Farewell_Traveler_Cakes_02 from '../images/cakeImages/06_Farewell_And_Reception_Traveler_Cakes/Farewell_Traveler_Cakes_02.png'
import Farewell_Traveler_Cakes_03 from '../images/cakeImages/06_Farewell_And_Reception_Traveler_Cakes/Farewell_Traveler_Cakes_03.png'
import Farewell_Traveler_Cakes_04 from '../images/cakeImages/06_Farewell_And_Reception_Traveler_Cakes/Farewell_Traveler_Cakes_04.png'
import Farewell_Traveler_Cakes_05 from '../images/cakeImages/06_Farewell_And_Reception_Traveler_Cakes/Farewell_Traveler_Cakes_05.png'
import reception_Traveler_Cakes_01 from '../images/cakeImages/06_Farewell_And_Reception_Traveler_Cakes/reception_Traveler_Cakes_01.png'
import reception_Traveler_Cakes_02 from '../images/cakeImages/06_Farewell_And_Reception_Traveler_Cakes/reception_Traveler_Cakes_02.png'
import reception_Traveler_Cakes_03 from '../images/cakeImages/06_Farewell_And_Reception_Traveler_Cakes/reception_Traveler_Cakes_03.png'
import reception_Traveler_Cakes_04 from '../images/cakeImages/06_Farewell_And_Reception_Traveler_Cakes/reception_Traveler_Cakes_04.png'
import reception_Traveler_Cakes_05 from '../images/cakeImages/06_Farewell_And_Reception_Traveler_Cakes/reception_Traveler_Cakes_05.png'

import Other_Cakes_You_May_like_01 from '../images/cakeImages/07_Other_Cakes_You_May_Like/Other_Cakes_You_May_like_01.png'
import Other_Cakes_You_May_like_02 from '../images/cakeImages/07_Other_Cakes_You_May_Like/Other_Cakes_You_May_like_02.png'
import Other_Cakes_You_May_like_03 from '../images/cakeImages/07_Other_Cakes_You_May_Like/Other_Cakes_You_May_like_03.png'
import Other_Cakes_You_May_like_04 from '../images/cakeImages/07_Other_Cakes_You_May_Like/Other_Cakes_You_May_like_04.png'
import Other_Cakes_You_May_like_05 from '../images/cakeImages/07_Other_Cakes_You_May_Like/Other_Cakes_You_May_like_05.png'
import Other_Cakes_You_May_like_06 from '../images/cakeImages/07_Other_Cakes_You_May_Like/Other_Cakes_You_May_like_06.png'
import Other_Cakes_You_May_like_07 from '../images/cakeImages/07_Other_Cakes_You_May_Like/Other_Cakes_You_May_like_07.png'
import Other_Cakes_You_May_like_08 from '../images/cakeImages/07_Other_Cakes_You_May_Like/Other_Cakes_You_May_like_08.png'
import Other_Cakes_You_May_like_09 from '../images/cakeImages/07_Other_Cakes_You_May_Like/Other_Cakes_You_May_like_09.png'

import twitter_Icon from '../images/Footer_Images/twitter_icon_footer.png'
// import instagram_Icon from '../images/instagram_Icon.svg'
import instagram_Icon from '../images/Footer_Images/instagram_icon_footer.png'
import tiktok_Icon from '../images/Footer_Images/tiktok_icon_footer.png'
import whatsapp_Icon from '../images/Footer_Images/whatsapp_icon_footer.png'
import phone_Icon from '../images/Footer_Images/phone_icon_footer.png'


// const AllOffersItems = [
//     // Our Partners Offer
//     {
//         id: "0",
//         CategoryNameEN: "Our Partners",
//         CategoryNameAR: "شركاؤنا",
//         Cakes: [
//             {
//                 id: '',
//                 img: lumina_logo,
//                 itemNameEn: "Up to 30% discount",
//                 itemNameAR: "خصم يصل إلى 30%",
//                 itemDescreptionEn: "Because every woman deserves care, we offer you a luxurious experience in your home",
//                 itemDescreptionAR: "لأن كل سيدة تستحق العناية، نقدم لكِ تجربة فاخرة في بيتك",
//                 // price: "25",
//             }
//         ]
//     },
// ]
const AllCakesItems = [

    // New Collection
    {
        id: "01",
        CategoryNameEN: "New Collection",
        CategoryNameAR: "أحدث الكيكات",
        Cakes: [
            {
                id: "01",
                img: New_Collection_Cake_01,
                itemNameEn: "New Collection Cake",
                itemNameAR: "كيكة المجموعة الجديدة",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25"
            },
            {
                id: "02",
                img: New_Collection_Cake_02,
                itemNameEn: "New Collection Cake",
                itemNameAR: "كيكة المجموعة الجديدة",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25"
            },
            {
                id: "03",
                img: New_Collection_Cake_03,
                itemNameEn: "New Collection Cake",
                itemNameAR: "كيكة المجموعة الجديدة",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25"
            },
            {
                id: "04",
                img: New_Collection_Cake_04,
                itemNameEn: "New Collection Cake",
                itemNameAR: "كيكة المجموعة الجديدة",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25"
            },
            {
                id: "05",
                img: New_Collection_Cake_05,
                itemNameEn: "New Collection Cake",
                itemNameAR: "كيكة المجموعة الجديدة",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25"
            },
            {
                id: "06",
                img: New_Collection_Cake_06,
                itemNameEn: "New Collection Cake",
                itemNameAR: "كيكة المجموعة الجديدة",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25"
            },
            {
                id: "07",
                img: New_Collection_Cake_07,
                itemNameEn: "New Collection Cake",
                itemNameAR: "كيكة المجموعة الجديدة",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25"
            },
            {
                id: "08",
                img: New_Collection_Cake_08,
                itemNameEn: "New Collection Cake",
                itemNameAR: "كيكة المجموعة الجديدة",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25"
            },
            {
                id: "09",
                img: New_Collection_Cake_09,
                itemNameEn: "New Collection Cake",
                itemNameAR: "كيكة المجموعة الجديدة",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25"
            },
            {
                id: "10",
                img: New_Collection_Cake_10,
                itemNameEn: "New Collection Cake",
                itemNameAR: "كيكة المجموعة الجديدة",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25"
            },
            {
                id: "11",
                img: New_Collection_Cake_11,
                itemNameEn: "New Collection Cake",
                itemNameAR: "كيكة المجموعة الجديدة",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25"
            },
            {
                id: "12",
                img: New_Collection_Cake_12,
                itemNameEn: "New Collection Cake",
                itemNameAR: "كيكة المجموعة الجديدة",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25"
            },
            {
                id: "13",
                img: New_Collection_Cake_13,
                itemNameEn: "New Collection Cake",
                itemNameAR: "كيكة المجموعة الجديدة",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25"
            },
            {
                id: "14",
                img: New_Collection_Cake_14,
                itemNameEn: "New Collection Cake",
                itemNameAR: "كيكة المجموعة الجديدة",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25"
            },
            {
                id: "15",
                img: New_Collection_Cake_15,
                itemNameEn: "New Collection Cake",
                itemNameAR: "كيكة المجموعة الجديدة",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25"
            },
            {
                id: "16",
                img: New_Collection_Cake_16,
                itemNameEn: "New Collection Cake",
                itemNameAR: "كيكة المجموعة الجديدة",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25"
            },
            {
                id: "17",
                img: New_Collection_Cake_17,
                itemNameEn: "New Collection Cake",
                itemNameAR: "كيكة المجموعة الجديدة",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25"
            },
            {
                id: "18",
                img: New_Collection_Cake_18,
                itemNameEn: "New Collection Cake",
                itemNameAR: "كيكة المجموعة الجديدة",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25"
            },
            {
                id: "19",
                img: New_Collection_Cake_19,
                itemNameEn: "New Collection Cake",
                itemNameAR: "كيكة المجموعة الجديدة",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25"
            },
            {
                id: "20",
                img: New_Collection_Cake_20,
                itemNameEn: "New Collection Cake",
                itemNameAR: "كيكة المجموعة الجديدة",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25"
            },
            {
                id: "21",
                img: New_Collection_Cake_21,
                itemNameEn: "New Collection Cake",
                itemNameAR: "كيكة المجموعة الجديدة",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25"
            },
            {
                id: "22",
                img: New_Collection_Cake_22,
                itemNameEn: "New Collection Cake",
                itemNameAR: "كيكة المجموعة الجديدة",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25"
            },
            {
                id: "23",
                img: New_Collection_Cake_23,
                itemNameEn: "New Collection Cake",
                itemNameAR: "كيكة المجموعة الجديدة",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25"
            },
            {
                id: "24",
                img: New_Collection_Cake_24,
                itemNameEn: "New Collection Cake",
                itemNameAR: "كيكة المجموعة الجديدة",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25"
            },
            {
                id: "25",
                img: New_Collection_Cake_25,
                itemNameEn: "New Collection Cake",
                itemNameAR: "كيكة المجموعة الجديدة",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25"
            }
        ]
    },


    // Birthday Cakes 
    {
        id: "01",
        CategoryNameEN: "Birthday Cakes",
        CategoryNameAR: "كيكات اعياد الميلاد",
        Cakes: [
            {
                id: '01',
                img: Birthday_Cakes_01,
                itemNameEn: "Birthday Cake",
                itemNameAR: "كيكة عيد ميلاد",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '02',
                img: Birthday_Cakes_02,
                itemNameEn: "Birthday Cake",
                itemNameAR: "كيكة عيد ميلاد",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '03',
                img: Birthday_Cakes_03,
                itemNameEn: "Birthday Cake",
                itemNameAR: "كيكة عيد ميلاد",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '04',
                img: Birthday_Cakes_04,
                itemNameEn: "Birthday Cake",
                itemNameAR: "كيكة عيد ميلاد",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '05',
                img: Birthday_Cakes_05,
                itemNameEn: "Birthday Cake",
                itemNameAR: "كيكة عيد ميلاد",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '06',
                img: Birthday_Cakes_06,
                itemNameEn: "Birthday Cake",
                itemNameAR: "كيكة عيد ميلاد",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '07',
                img: Birthday_Cakes_07,
                itemNameEn: "Birthday Cake",
                itemNameAR: "كيكة عيد ميلاد",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '08',
                img: Birthday_Cakes_08,
                itemNameEn: "Birthday Cake",
                itemNameAR: "كيكة عيد ميلاد",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '09',
                img: Birthday_Cakes_09,
                itemNameEn: "Birthday Cake",
                itemNameAR: "كيكة عيد ميلاد",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },

        ]
    },
    // Wedding Anniversary Cakes
    {
        id: "02",
        CategoryNameEN: "Wedding Anniversary & Bachelorette Party Cakes",
        CategoryNameAR: "كيكات ذكرى الزواج وتوديع العزوبية",
        Cakes: [
            {
                id: '01',
                img: Wedding_Anniversary_Cakes_01,
                itemNameEn: "Wedding Anniversary",
                itemNameAR: "كيكة ذكرى الزواج",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '02',
                img: Wedding_Anniversary_Cakes_02,
                itemNameEn: "Wedding anniversary",
                itemNameAR: "كيكة ذكرى الزواج",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '03',
                img: Wedding_Anniversary_Cakes_03,
                itemNameEn: "Wedding anniversary",
                itemNameAR: "كيكة ذكرى الزواج",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '04',
                img: Wedding_Anniversary_Cakes_04,
                itemNameEn: "Wedding anniversary",
                itemNameAR: "كيكة ذكرى الزواج",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '05',
                img: Wedding_Anniversary_Cakes_05,
                itemNameEn: "Wedding anniversary",
                itemNameAR: "كيكة ذكرى الزواج",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '06',
                img: Wedding_Anniversary_Cakes_06,
                itemNameEn: "Wedding anniversary",
                itemNameAR: "كيكة ذكرى الزواج",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '01',
                img: Bachelorette_Party_Cakes_01,
                itemNameEn: "Bachelorette Party Cake",
                itemNameAR: "كيكة توديع العزوبية",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '02',
                img: Bachelorette_Party_Cakes_02,
                itemNameEn: "Bachelorette Party Cake",
                itemNameAR: "كيكة توديع العزوبية",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '03',
                img: Bachelorette_Party_Cakes_03,
                itemNameEn: "Bachelorette Party Cake",
                itemNameAR: "كيكة توديع العزوبية",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '04',
                img: Bachelorette_Party_Cakes_04,
                itemNameEn: "Bachelorette Party Cake",
                itemNameAR: "كيكة توديع العزوبية",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '05',
                img: Bachelorette_Party_Cakes_05,
                itemNameEn: "Bachelorette Party Cake",
                itemNameAR: "كيكة توديع العزوبية",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            }

        ]
    },
    // Engagement & Queen Cakes
    {
        id: "08",
        CategoryNameEN: "Engagement & Queen Cakes",
        CategoryNameAR: "كيكات الخطوبة والملكة",
        Cakes: [
            {
                id: '01',
                img: Engagement_Cakes_01,
                itemNameEn: "Engagement Cakes",
                itemNameAR: "كيكة الخطوبة",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '02',
                img: Engagement_Cakes_02,
                itemNameEn: "Engagement Cakes",
                itemNameAR: "كيكة الخطوبة",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '03',
                img: Engagement_Cakes_03,
                itemNameEn: "Engagement Cakes",
                itemNameAR: "كيكة الخطوبة",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '04',
                img: Engagement_Cakes_04,
                itemNameEn: "Engagement Cakes",
                itemNameAR: "كيكة الخطوبة",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '05',
                img: Engagement_Cakes_05,
                itemNameEn: "Engagement Cakes",
                itemNameAR: "كيكة الخطوبة",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '01',
                img: Queen_Cakes_01,
                itemNameEn: "Queen Cakes",
                itemNameAR: "كيكة الملكة",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '02',
                img: Queen_Cakes_02,
                itemNameEn: "Queen Cakes",
                itemNameAR: "كيكة الملكة",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '03',
                img: Queen_Cakes_03,
                itemNameEn: "Queen Cakes",
                itemNameAR: "كيكة الملكة",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '04',
                img: Queen_Cakes_04,
                itemNameEn: "Queen Cakes",
                itemNameAR: "كيكة الملكة",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '05',
                img: Queen_Cakes_05,
                itemNameEn: "Queen Cakes",
                itemNameAR: "كيكة الملكة",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '06',
                img: Queen_Cakes_06,
                itemNameEn: "Queen Cake",
                itemNameAR: "كيكة الملكة",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            }

        ]
    },
    // Baby Shower & Gender Determination Cakes
    {
        id: "03",
        CategoryNameEN: "Baby Shower & Gender Determination Cakes",
        CategoryNameAR: "كيكات استقبال المولود وتحديد جنس المولود والأربعين",
        Cakes: [
            {
                id: '01',
                img: Baby_Shower_Cakes_01,
                itemNameEn: "Baby Shower Cake",
                itemNameAR: "كيكة استقبال المولود",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '02',
                img: Baby_Shower_Cakes_02,
                itemNameEn: "Baby Shower Cake",
                itemNameAR: "كيكة استقبال المولود",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '03',
                img: Baby_Shower_Cakes_03,
                itemNameEn: "Baby Shower Cake",
                itemNameAR: "كيكة استقبال المولود",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '04',
                img: Baby_Shower_Cakes_04,
                itemNameEn: "Baby Shower Cake",
                itemNameAR: "كيكة استقبال المولود",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '05',
                img: Baby_Shower_Cakes_05,
                itemNameEn: "Baby Shower Cake",
                itemNameAR: "كيكة استقبال المولود",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '01',
                img: Gender_Determination_Cakes_01,
                itemNameEn: "Gender Determination Cakes",
                itemNameAR: "كيكة تحديد جنس المولود",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '02',
                img: Gender_Determination_Cakes_02,
                itemNameEn: "Gender Determination Cakes",
                itemNameAR: "كيكة تحديد جنس المولود",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '03',
                img: Gender_Determination_Cakes_03,
                itemNameEn: "Gender Determination Cakes",
                itemNameAR: "كيكة تحديد جنس المولود",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '04',
                img: Gender_Determination_Cakes_04,
                itemNameEn: "Gender Determination Cakes",
                itemNameAR: "كيكة تحديد جنس المولود",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '01',
                img: Forty_Days_Party_Cakes_01,
                itemNameEn: "Forty Days Party Cake",
                itemNameAR: "كيكة حفلة الأربعين",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '02',
                img: Forty_Days_Party_Cakes_02,
                itemNameEn: "Forty Days Party Cake",
                itemNameAR: "كيكة حفلة الأربعين",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '03',
                img: Forty_Days_Party_Cakes_03,
                itemNameEn: "Forty Days Party Cake",
                itemNameAR: "كيكة حفلة الأربعين",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '04',
                img: Forty_Days_Party_Cakes_04,
                itemNameEn: "Forty Days Party Cake",
                itemNameAR: "كيكة حفلة الأربعين",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            }
        ]
    },
    // Surgery Success Party Cakes
    {
        id: "04",
        CategoryNameEN: "Surgery Success Party Cakes",
        CategoryNameAR: "كيكات حفلات نجاح العملية",
        Cakes: [
            {
                id: '01',
                img: Surgery_Success_Party_Cakes_01,
                itemNameEn: "Surgery success",
                itemNameAR: "الحمد لله على السلامة",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '02',
                img: Surgery_Success_Party_Cakes_02,
                itemNameEn: "Surgery success",
                itemNameAR: "الحمد لله على السلامة",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '03',
                img: Surgery_Success_Party_Cakes_03,
                itemNameEn: "Surgery success",
                itemNameAR: "الحمد لله على السلامة",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '04',
                img: Surgery_Success_Party_Cakes_04,
                itemNameEn: "Surgery success",
                itemNameAR: "الحمد لله على السلامة",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '05',
                img: Surgery_Success_Party_Cakes_05,
                itemNameEn: "Surgery success",
                itemNameAR: "الحمد لله على السلامة",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
        ]
    },
    // Achieving Goals Cakes
    {
        id: "05",
        CategoryNameEN: "Opening Projects & Achieving Goals Cakes",
        CategoryNameAR: "كيكات افتتاح المشاريع، وتحقيق الأهداف",
        Cakes: [
            {
                id: '01',
                img: Achieving_Goals_Cakes_01,
                itemNameEn: "Achieving Goals Cakes",
                itemNameAR: "كيكة تحقيق الأهداف",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '02',
                img: Achieving_Goals_Cakes_02,
                itemNameEn: "Achieving Goals Cakes",
                itemNameAR: "كيكة تحقيق الأهداف",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '03',
                img: Achieving_Goals_Cakes_03,
                itemNameEn: "Achieving Goals Cakes",
                itemNameAR: "كيكة تحقيق الأهداف",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '04',
                img: Achieving_Goals_Cakes_04,
                itemNameEn: "Achieving Goals Cakes",
                itemNameAR: "كيكة تحقيق الأهداف",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '05',
                img: Achieving_Goals_Cakes_05,
                itemNameEn: "Achieving Goals Cakes",
                itemNameAR: "كيكة تحقيق الأهداف",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            }

        ]
    },
    // Farewell & Reception For The Traveler Cakes
    {
        id: "06",
        CategoryNameEN: "Farewell & Reception For The Traveler Cakes",
        CategoryNameAR: "كيكات توديع واستقبال المسافر",
        Cakes: [
            {
                id: '01',
                img: Farewell_Traveler_Cakes_01,
                itemNameEn: "Farewell Traveler Cake",
                itemNameAR: "كيكة توديع المسافر",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '02',
                img: Farewell_Traveler_Cakes_02,
                itemNameEn: "Farewell Traveler Cake",
                itemNameAR: "كيكة توديع المسافر",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '03',
                img: Farewell_Traveler_Cakes_03,
                itemNameEn: "Farewell Traveler Cake",
                itemNameAR: "كيكة توديع المسافر",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '04',
                img: Farewell_Traveler_Cakes_04,
                itemNameEn: "Farewell Traveler Cake",
                itemNameAR: "كيكة توديع المسافر",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '05',
                img: Farewell_Traveler_Cakes_05,
                itemNameEn: "Farewell Traveler Cake",
                itemNameAR: "كيكة توديع المسافر",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '01',
                img: reception_Traveler_Cakes_01,
                itemNameEn: "Traveler Reception Cake",
                itemNameAR: "كيكة استقبال المسافر",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '02',
                img: reception_Traveler_Cakes_02,
                itemNameEn: "Traveler Reception Cake",
                itemNameAR: "كيكة استقبال المسافر",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '03',
                img: reception_Traveler_Cakes_03,
                itemNameEn: "Traveler Reception Cake",
                itemNameAR: "كيكة استقبال المسافر",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '04',
                img: reception_Traveler_Cakes_04,
                itemNameEn: "Traveler Reception Cake",
                itemNameAR: "كيكة استقبال المسافر",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '05',
                img: reception_Traveler_Cakes_05,
                itemNameEn: "Traveler Reception Cake",
                itemNameAR: "كيكة استقبال المسافر",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
        ]
    },
    // Other Cakes You May Like!
    {
        id: "07",
        CategoryNameEN: "Other Cakes You May Like!",
        CategoryNameAR: "كيكات أخرى قد تعجبك!",
        Cakes: [
            {
                id: '01',
                img: Other_Cakes_You_May_like_01,
                itemNameEn: "Happy Mother Day",
                itemNameAR: "كيكة عيد الأم",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '02',
                img: Other_Cakes_You_May_like_02,
                itemNameEn: "Happy Women Day",
                itemNameAR: "كيكة يوم المرأة العالمي",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '03',
                img: Other_Cakes_You_May_like_03,
                itemNameEn: "Happy Friends Day Cake",
                itemNameAR: "كيكة اليوم العالمي للصداقة",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '04',
                img: Other_Cakes_You_May_like_04,
                itemNameEn: "Foundation Day Cake",
                itemNameAR: "كيكة يوم التأسيس",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '05',
                img: Other_Cakes_You_May_like_05,
                itemNameEn: "Happy New Year Cake",
                itemNameAR: "كيكة العام الجديد",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '06',
                img: Other_Cakes_You_May_like_06,
                itemNameEn: "Congratulations Cake",
                itemNameAR: "كيكة مبروك التخرج",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '07',
                img: Other_Cakes_You_May_like_07,
                itemNameEn: "Valntine Cake",
                itemNameAR: "كيكة الفلنتاين",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '08',
                img: Other_Cakes_You_May_like_08,
                itemNameEn: "2 Months Cake",
                itemNameAR: "كيكة شهرين",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
            {
                id: '09',
                img: Other_Cakes_You_May_like_09,
                itemNameEn: "Happy Day Cake",
                itemNameAR: "كيكة يوم سعيد",
                itemDescreptionEn: "Available (cup - 4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "متوفر ( كوب - 4 - 6 - 8 - 10 انش )",
                price: "25",
            },
        ]
    }
]

const SocialMediaIcons = [
    {
        icon: instagram_Icon,
        link: "https://www.instagram.com/shahd_cakery/"
    },
    {
        icon: twitter_Icon,
        link: "https://twitter.com/shahd_cakery"
    },
    {
        icon: tiktok_Icon,
        link: "https://www.tiktok.com/@shahd_cakery"
    },
    {
        icon: whatsapp_Icon,
        link: "https://wa.me/966571220234"
    },
    {
        icon: phone_Icon,
        link: "tel:+966571220234"
    },
]

export { SocialMediaIcons, AllCakesItems }